.container-thank-all {
    width: 100%;
    min-height: 90vh;
    /* background-color: blue; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url('../../assets/imagenes/portada-gracias.jpg');
    background-size: cover;
}

.container-thank-text {
    width: 60%;
    min-height: 30vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0.647);
    color: white;
    gap: 15px;
}

.title-thank {
    font-weight: 300;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
    font-size: 30px;
    text-align: center;
}

.subtitle-thank {
    font-weight: 200;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
    font-size: 20px;
    text-align: center;
}

@media screen and (min-width:320px) and (max-width:700px) {
    .container-thank-text {
        width: 90%;
    }

    .title-thank {
        text-align: center;
    }
}

@media screen and (min-width:320px) and (max-width:500px) {
  
    .title-thank {
      font-size: 25px;
    }
    .subtitle-thank {
      font-size: 18px;
    }
}
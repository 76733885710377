.div-proyects {
    width: 100%;
    /* min-height: 100vh; */
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    position: relative;
}

.div-proyectos-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px
}

.container-proyectos {
    width: 30%;
    /* height: 260px; */
    align-items: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;

}

.title-proyect {
    font-size: 24px;
    font-weight: 200;
    text-align: end;
    margin-right: 50px;
}

.project-name {
    text-align: center;
    font-size: 28px;
    font-weight: 100;
}

.clase1 {
    width: 100%;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.5);
    opacity: 0.8;
}

.clase1:hover {
    opacity: 1;
}



.container-button-ver-todos {
    width: 100%;
    /* position: absolute; */
    /* bottom: 0; */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5%;
}

.button-ver-todos {
    width: 15%;
    padding: 3px;
    border: none;
    color: black;
    background-color: transparent;
    /* background-color: grey; */
    font-size: 22px;
    font-weight: 100;
    /* border-radius: 2px; */
    position: relative;
    cursor: pointer;
    margin-bottom: 20px;

}

/* .button-ver-todos:hover{
    background-color: rgba(128, 128, 128, 0.637);
} */

.button-ver-todos::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: grey;
    transform: scaleX(0);
    transition: transform 0.3s ease;
}

.button-ver-todos:hover::after {
    transform: scaleX(1);
}

@media (min-width: 501px) and (max-width: 950px) {
    .div-proyectos-container {
        flex-direction: column;
        align-items: center;
    }

    .title-proyect {
        text-align: center;
        margin: 0;
        margin-top: 40px;
    }

    .clase1 {
        width: 80%;

    }

    .container-proyectos {
        width: 70%;
    }

    .container-button-ver-todos {
        position: relative;
        width: 100%;
    }

    .button-ver-todos {
        width: 50%;
    }
}

@media (min-width: 320px) and (max-width: 500px) {


    .div-proyectos-container {
        flex-direction: column;
        align-items: center;
    }

    .title-proyect {
        text-align: center;
        margin: 0;
        margin-top: 40px;
    }

    .clase1 {
        width: 100%;

    }

    .container-proyectos {
        width: 70%;
    }

    .container-button-ver-todos {
        position: relative;
        width: 100%;
    }

    .button-ver-todos {
        width: 50%;
    }
}
.div-arquitecture {
    width: 100%;
    min-height: 100vh;
    /* Asegura que el contenedor ocupe toda la altura de la ventana */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: whitesmoke;
    overflow: hidden;
}

.arquitecture-gallery {
    position: relative;
    width: 100%;
    height: 100vh;
    /* Asegura que el contenedor de la imagen ocupe toda la altura del viewport */
}

.arquitecture-gallery :before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.353);
    z-index: 1;
}

.img-arquitecture-carrousel {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
}

.div-text-interior {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    color: white;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
    gap: 15px;
}

.texto-bienvenida-interior {
    font-size: 30px;
    text-align: center;
    font-weight: 100;
}

.texto-presentacion-interior {
    font-size: 40px;
    font-weight: 300;
    font-style: italic;
    text-align: center;
}

.container-presentacion-interiorismo {
    width: 100%;
    min-height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    margin-top: 10px;
}

.container-presentacion-interiorismo .container-presentacion-h3-h6 {
    width: 100%;
    /* background-color: rgb(255, 60, 0); */
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.h6-presentacion-interiorismo {
    font-size: 20px;
    font-weight: 200;
}

.h3-presentacion-interiorismo {
    font-size: 20px;
    font-weight: 600;
}

.p-presentacion-interiorismo {
    width: 100%;
    font-size: 18px;
    font-weight: 200;
    text-align: center;
}

.container-presentacion-interiorismo-p {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 10px;
    margin-bottom: 10px;
}

.p-servicios-interiorismo {
    text-align: center;
    font-size: 18px;
    font-weight: 400;
}

.p-servicios-interiorismo:hover {
    color: rgba(0, 0, 0, 0.596);
    cursor: pointer;
}

.div-container-interiorismo {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;  
}

.div-container-item-interiorismo {
    width: 90%;
    height: 70vh;
    display: flex;
    align-items: center;

}

.div-container-item-reverse-interiorismo {
    flex-direction: row-reverse;
}

.div-contain-info-interiorismo {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

}
.container-proyecto-interiorismo {
    width: 80%;
    margin-bottom: 20px;
}
.p-proyecto-interiorismo {
    text-align: start;
    font-size: 20px;
    font-weight: 300;
    font-style: italic;
}
.div-proyecto-obra-interiorismo{
    width: 90%;
    min-height: 50%;
}

.title-interiorismo {
    font-size: 20px;
    font-weight: 600;
    
}

.info-detallada-interiorismo {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.h2-title-interiorismo {
    font-size: 20px;
    font-weight: 300;
    margin-top: 10px;
}

.p-subtitle-interiorismo {
    font-size: 20px;
    font-weight: 200;
    font-style: italic;
    border-bottom: 1px solid black;
}

.descripcion-interiorismo {
    width: 100%;
    font-weight: 200;
    font-size: 16px;
    text-wrap: wrap;
}
.div-imagen-interiorismo{
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imagen-interiorismo {
    width: 100%;
    height: 100%;
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;

}

@media screen and (min-width: 320px) and (max-width: 950px) {

    .div-container-item-interiorismo {
        flex-direction: column;
        height: 100%;
    }

    .h3-presentacion-interiorismo {
        text-align: center;
    }

    .div-contain-info-arquitectura {
        width: 80%;
    }

    
    .container-presentacion-interiorismo-p {
        width: 100%;
    }
    
    .div-contain-info-interiorismo {
        width: 90%;
        margin-bottom: 20px;
    }
    
    .div-imagen-interiorismo{
        width: 70%;
        
    }
    .imagen-interiorismo {
        width: 100%;
    }
    .div-proyecto-obra-interiorismo{
        width: 90%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        min-height: 50%;
        align-items: center;
    }
    .div-si-contas {
        width: 90%;
        display: flex;
        justify-content: center;
    }
}


@media screen and (min-width: 320px) and (max-width: 539px) {
    
    .container-presentacion-interiorismo {
        width: 90%;
    }

    .container-presentacion-h3-h6 {
        width: 100%;
    }

    .container-presentacion-interiorismo-p {
        width: 100%;
        gap: 3px;
    }
    .p-servicios-interiorismo {
        font-size: 12px;
        text-align: center;
    }

    .h3-presentacion-interiorismo {
        text-align: center;
    }
    .div-imagen-interiorismo{
        width: 100%;
    }
    .imagen-interiorismo {
        width: 80%;
        margin-top: 15px;
    }

    .descripcion-interiorismo {
        text-wrap: wrap;
    }
    


}


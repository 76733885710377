.component-divider {
    display: block;
}

.horizontal {
    width: 100%;
}

.vertical {
    height: 100%;
}
.nav-container-hamburguesa {
    width: 100%;
    min-height: 20vh;
    background-color: rgba(0, 0, 0, 0.566);
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 90px;
    border: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;   
    z-index: 1;
}
.nav-container-hamburguesa-black{
    width: 100%;
    min-height: 20vh;
    background-color: rgb(0, 0, 0);
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 90px;
    border: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;   
    z-index: 1;
}
.profile {
    display: flex;
    flex-direction: column;
    padding: 1.4rem;
    gap: 15px;
}

.link-menu-hamburguesa {
    color: white;
    font-size: 18px;
    font-weight: 100;
    background-color: transparent;
    border: none;
    text-align: start;
}

@media screen and (min-width: 320px) and (max-width: 450px) {
    .nav-container-hamburguesa {
        /* width: 60%; */
        display: flex;
    }
}
@media screen and (min-width: 767px) {
    .nav-container-hamburguesa {
        display: none; /* Ocultar los enlaces en dispositivos móviles */
    } 
}
.proyect-detail {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: whitesmoke;
    gap: 10px;
}

.container-proyect-detail-portada {
    position: relative;
    width: 100%;
    height: 100vh;
}

.container-proyect-detail-portada::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.575);
    z-index: 1;
}

.imagen-portada-proyect-detail {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
}

.container-name-proyect-detail {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    color: white;
    text-shadow: 0px 5px 10px rgba(0, 0, 0, 1);
    gap: 15px;
}

.obras-proyect-detail {
    font-size: 30px;
    text-align: center;
    font-weight: 100;
    color: white;
}

.name-proyect-detail {

    font-size: 30px;
    text-align: center;
    font-weight: 300;
    color: white;

}

.contain-description-proyect-detail {
    /* background-color: white; */
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 3px;
    margin-top: 10px;
    text-align: center;
    /* box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.322); */
}

.title-description-proyect-detail {
    font-size: 25px;
    font-weight: 100;
}

.p-description-proyect-detail {
    font-size: 20px;
}

.div-title-projet-detail {
    width: 90%;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    /* box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.322); */
    padding: 5px;
}

.div-title-projet-detail .title-project {
    font-size: 30px;
    font-weight: 300;
}

.div-title-projet-detail .description-project-detail {
    font-size: 20px;
    font-weight: 200;
    color: black;
    margin-top: 15px;
}

.contain-image-proyect-detail {
    width: 100%;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.title-proyecto-details {
    font-size: 25px;
    font-weight: 200;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.div-section-title {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 10px;

}

.div-image-detail {
    width: 40%;
    display: flex;
    justify-content: center;
}

.image-proyect-detail {
    width: 100%;
    margin: 10px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.322);
    border-radius: 3px;
}
.div-video-detail {
    width: 40%;
    max-height: 70vh;
    display: flex;
    justify-content: center;
}
.video-proyect-detail{
    width: 100%;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.322);
    border-radius: 3px;
    object-fit: cover;
}
/*codigo para cuando no se encuentra el proyecto*/
.proyect-not-found {
    width: 100%;
    height: 60vh; 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: whitesmoke;
}


.not-found-message {
    font-size: 24px;
    
}

.button-not-found {
    width: 15%;
    padding: 3px;
    border: none;
    color: black;
    background-color: transparent;
    font-size: 22px;
    font-weight: 100;
    position: relative;
    cursor: pointer;
    margin-top: 40px;
    margin-bottom: 20px;
    transition: transform 0.7s ease-out; 
}

.button-not-found:hover {
    transform: scale(1.05);
}

.button-not-found::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: grey;
    transform: scaleX(0);
    transition: transform 0.3s ease;
}

.button-not-found:hover::after {
    transform: scaleX(1);
}

@media screen and (min-width:320px) and (max-width:770px){
    .div-section-title{
        flex-direction: column;
    }
    .div-image-detail {
        width: 70%;
    }
    .div-video-detail{
        width: 70%;
    }
    .contain-description-proyect-detail {
        width: 100%;
    }
    .title-description-proyect-detail {
        font-size: 20px;
    }
    .button-not-found{
        width: 40%;
    }
}
@media screen and (min-width:320px) and (max-width:500px){
    .contain-description-proyect-detail {
        width: 100%;
        flex-direction: column;
        gap: 10px;
    }
    .title-proyecto-details {
        font-size: 26px;
    }
    .div-title-projet-detail .title-project {
        text-align: center;
    }
}
.div-arquitecture {
    width: 100%;
    min-height: 100vh;
    /* Asegura que el contenedor ocupe toda la altura de la ventana */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: whitesmoke;
    overflow: hidden;

}

.arquitecture-gallery {
    position: relative;
    width: 100%;
    height: 100vh;
}

.arquitecture-gallery::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.575);
    /* Cambia el valor de opacidad según sea necesario */
    z-index: 1;
}

.img-arquitecture-carrousel {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
}

.div-text-arquitectura {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    color: white;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
    gap: 15px;
}

.texto-bienvenida-arquitectura {
    font-size: 30px;
    text-align: center;
    font-weight: 100;
}

.texto-presentacion-arquitectura {
    font-size: 40px;
    font-weight: 300;
    font-style: italic;
    text-align: center;

}

.scroll-link-arquitecture {
    position: absolute;
    bottom: 0;
    margin-bottom: 20px;
}

.boton-presupuesto {
    font-size: 20px;
    font-weight: 100;
    background-color: transparent;
    color: white;
    border: 1px solid white;
    padding: 3px 8px;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
    text-align: center;
}

.boton-presupuesto:hover {
    background: rgba(255, 255, 255, 0.308);
}


.container-presentacion-arquitectura {
    width: 100%;
    min-height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    margin-top: 10px;
}

.container-presentacion-h3-h6 {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.h3-presentacion-arquitectura {
    font-size: 20px;
    font-weight: 600;
}

.h6-presentacion-arquitectura {
    font-size: 18px;
    font-weight: 200;
}

.p-presentacion-arquitectura {
    width: 100%;
    font-size: 18px;
    font-weight: 200;
    text-align: center;
}

.container-presentacion-arquitectura-p {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 10px;
    margin-bottom: 10px;

}

.p-servicios-arquitectura {
    text-align: center;
    font-size: 20px;
    font-weight: 400;
}

.p-servicios-arquitectura:hover {
    color: rgba(0, 0, 0, 0.596);
    cursor: pointer;
}


.p-recomendacion-arquitectura {
    width: 100%;
    font-size: 18px;
    font-weight: 200;
    text-align: center;
}

.div-container-arquitecture {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
}
.div-si-contas{
    width: 90%;
}
.container-proyecto-propiedad {
    width: 80%;
    margin-bottom: 20px;
}

.p-proyecto-propiedad {
    text-align: start;
    font-size: 20px;
    font-weight: 300;
    font-style: italic;
    /* margin-bottom: 10px; */
}


.container-remodelacion-propiedad {
    width: 80%;
    display: flex;
    margin-bottom: 20px;
    margin-top: 20px;
}

.p-remodelacion-propiedad {
    text-align: start;
    font-size: 20px;
    font-weight: 300;
    font-style: italic;
    /* margin-bottom: 10px; */

}

.div-container-item {
    width: 90%;
    height: 70%;
    display: flex;
    align-items: center;

    /* gap: 20px; */
}

.div-container-item-reverse {
    flex-direction: row-reverse;

}
.div-proyecto-obra-remodelacion{
    width: 90%;
    height: 70%;
}

.div-contain-info-arquitectura {
    width: 50%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

}

.info-detallada-arquitectura {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.title-arquitectura {
    font-size: 25px;
    font-weight: 600;

}

.h2-title-arquitectura {
    font-size: 20px;
    font-weight: 300;
    margin-top: 20px;
}

.p-subtitle-arquitectura {
    font-size: 20px;
    font-weight: 200;
    font-style: italic;
    border-bottom: 1px solid black;

}

.descripcion-arquitectura {
    width: 100%;
    font-weight: 200;
    font-size: 16px;
    text-wrap: nowrap;
}

.div-imagen-arquitectura {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imagen-arquitecture {
    width: 100%;
    height: 100%;
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
}





@media screen and (min-width: 320px) and (max-width: 950px) {
    .container-presentacion-arquitectura {
        width: 100%;
    }
    .div-proyecto-obra-remodelacion {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .container-presentacion-h3-h6 {
        width: 90%;
    }

    .container-presentacion-arquitectura-p {
        width: 100%;

    }

    .h3-presentacion-arquitectura {
        text-align: center;
    }

    .imagen-arquitecture {
        width: 80%;
        margin-top: 15px;
        height: 80%;
    }

    .descripcion-arquitectura {
        text-wrap: wrap;
    }

    .p-servicios-arquitectura {
        font-size: 18px;
    }

    .div-imagen-arquitectura {
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    .div-contain-info-arquitectura {
        width: 100%;
        height: 100%;
    }
    .div-container-item {
        flex-direction: column;
        height: 100%;

    }

}
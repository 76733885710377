.div-servicios {
    width: 100%;
    min-height: 100vh;
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.div-fondo-servicios {
    position: relative;
    width: 100%;
    min-height: 100vh;
    background-color: whitesmoke;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;
}

/* .div-title-servicios {
    width: 100%;
    z-index: 2;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 15px;
    margin-right: 60px;
    margin-bottom: 10px;
    padding-right: 5px;
} */
.div-title-servicios {
    width: 100%;
    z-index: 2;
    display: flex;
    justify-content: end; /* Cambiado a center para centrar horizontalmente */
    align-items: center;
    gap: 15px;
    margin-right: 130px;
    margin-bottom: 10px;
    padding-right: 5px;
    position: absolute; /* Asegura que esté posicionado correctamente */
    text-align: center; /* Centra el texto */
}

.fondo-servicios {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 75vh;
    object-fit: cover;
    z-index: 0;
}

.div-servicios .title-servicios {
    font-size: 28px;
    font-weight: 200;
    text-align: end;
    /* text-shadow: 0px 8px 10px black; */
    text-shadow: 0px 5px 10px black;

    color: black;
}

.div-servicios .title-servicios2 {
    font-size: 28px;
    font-weight: 400;
    text-align: end;
    /* text-shadow: 0px 8px 10px black; */
    text-shadow: 0px 5px 10px black;

    color: black;
}


.div-map-servicios {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    position: absolute;
    margin-top: 35%;

}

.servicio-item {
    width: 30%;
    height: 260px;
    position: relative;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 3px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.div-map-servicios .servicio-item .icono-servicio {
    width: 50px;
    height: 50px;
    color: black;
    margin-top: 10px;
}

.div-map-servicios .servicio-item .type-container {
    color: black;
    font-weight: 600;
    font-size: 25px;
}

.div-map-servicios .servicio-item .text-container {
    width: 80%;
    color: black;
    font-size: 20px;
    font-weight: 200;
    text-align: center;
}

.div-map-servicios .servicio-item .button-servicios {
    width: 30%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    font-weight: 200;
    border: none;
    padding: 3px;
    position: relative;
    cursor: pointer;
    margin-bottom: 10px;
}


.div-map-servicios .servicio-item .button-servicios::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: grey;
    transform: scaleX(0);
    transition: transform 0.3s ease;
}

.div-map-servicios .servicio-item .button-servicios:hover::after {
    transform: scaleX(1);
}



@media (max-width: 768px) {

    .div-map-servicios .servicio-item .type-container {
        font-size: 25px;
    }

    .div-map-servicios .servicio-item .text-container {
        font-size: 18px;
        margin-bottom: 20px;

    }
    .div-title-servicios{
        margin: 0;
        background-color: rgba(245, 245, 245, 0.626);
       
    }
    .div-servicios .title-servicios2 {
        text-shadow: 0px 5px 10px white;

    }

    .div-servicios .title-servicios {
        text-shadow: 0px 5px 10px white;

    }

}



@media (min-width: 501px) and (max-width: 1028px) {
    .servicio-item {
        width: 60%;
    }

    .div-map-servicios {
        flex-direction: column;
        align-items: center;
        position: relative;
        margin: 0;
    }

    .div-fondo-servicios {
        min-height: 70vh;
        flex-direction: column;
        align-items: center;
        position: relative;
    }

    .div-title-servicios {
        position: absolute;
        bottom: 0;
        margin-bottom: 80px;
    }


}

@media (min-width: 320px) and (max-width: 500px) {
    .servicio-item {
        width: 70%;
    }

    .div-fondo-servicios {
        width: 100%;
        min-height: 50vh;
        position: relative;
    }
    .fondo-servicios{
        width: 100%;
        object-position: 55%;
        object-fit: cover;
    }

  
    .div-map-servicios {
        flex-direction: column;
        align-items: center;
        position: relative;
        margin-bottom: 30px;
    }

    .div-title-servicios {
        margin: 0;
        position: absolute;
        bottom: 0;
        justify-content: center;
        text-align: center;
        width: 100%;
        padding: 0; /*se saco padding para ver como se ve en ios */
        padding-top: 2px;

    }
    .div-servicios .title-servicios2 {
        font-weight: 200;

    }
}
.div-home {
    width: 100%;
    min-height: 100vh;
    /* Asegura que el contenedor ocupe toda la altura de la ventana */
    display: flex;
    flex-direction: column;
}

.div-text-welcome {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    color: white; /* Asegura que el texto sea visible sobre el carrusel */
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 1); /* Añade un sombreado al texto para mejorar la legibilidad */
    gap: 20px;
}

.texto-presentacion {
    font-size: 40px;
    font-weight: 300;
    text-align: center;
    font-style: italic;
}

.texto-bienvenida {
    font-size: 25px;
    font-weight: 100;
    text-align: center;
   
    
   
    
}

.image-gallery {
    position: relative;
    width: 100%;
    height: 100vh;
    /* Asegura que el contenedor de la imagen ocupe toda la altura del viewport */
}

.image-gallery:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.353);
    /* Ajusta la opacidad según tu preferencia */
    z-index: 1;
    /* Asegura que el overlay esté por encima de la imagen */
}

.img-home-carrousel {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Asegura que la imagen cubra completamente el contenedor */
    z-index: 0;
}

@media screen and (max-width: 768px) {
    .image-gallery-container {
        max-width: 100%;
    }

   

    /* .texto-presentacion {
        font-size: 50px;
        top: 50%;
    } */
}
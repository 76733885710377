.div-all-proyects {
    width: 100%;
    min-height: 70vh;
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.div-all-proyectos-container {
    width: 90%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 20px;
    justify-items: center;

}
/* .div-all-proyectos-container {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
    background-color: aqua;

} */

/* .container-all-proyectos {
    width: 300px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: transform 0.7s ease-out;

} */
.container-all-proyectos {
    width: 100%;
    max-width: 600px;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: transform 0.7s ease-out;
}


.container-all-proyectos:hover {
    transform: scale(1.05);
    /* Escala el contenedor cuando se hace hover */
}

.todos-los-proyectos {
    width: 90%;
    height: 75%;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.5);


}

.project-name-all {
    text-align: center;
    /* margin-top: 18px; */
    font-weight: 300;
    font-size: 20px;
}

.button-back {
    width: 15%;
    padding: 3px;
    border: none;
    color: black;
    background-color: transparent;
    font-size: 22px;
    font-weight: 100;
    position: relative;
    cursor: pointer;
    margin-top: 40px;
    margin-bottom: 20px;
}

.button-back::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: grey;
    transform: scaleX(0);
    transition: transform 0.3s ease;
}

.button-back:hover::after {
    transform: scaleX(1);
}

/* @media screen and (min-width: 500px) and (max-width: 800px) {
    .div-all-proyectos-container {
        flex-direction: column;
        align-items: center;
    }

    .container-all-proyectos {
        width: 70%;
    }

    .button-back {
        width: 40%;
    }
}

@media screen and (min-width: 320px) and (max-width: 499px) {
    .div-all-proyectos-container {
        flex-direction: column;
        align-items: center;
    }

    .container-all-proyectos {
        width: 90%;
    }

    .button-back {
        width: 60%;
    }
} */

@media screen and (min-width: 500px) and (max-width: 800px) {
    .div-all-proyectos-container {
        grid-template-columns: repeat(1, 1fr);
    }

    .container-all-proyectos {
        width: 100%;
    }
    
    .button-back {
        width: 40%;
    }
}

@media screen and (min-width: 320px) and (max-width: 499px) {
    .div-all-proyectos-container {
        grid-template-columns: 1fr;
    }

    .container-all-proyectos {
        width: 100%;
        height: 300px;
    }

    .button-back {
        width: 60%;
    }
}
@media screen and (min-width: 371px) and (max-width: 499px) {
    .div-all-proyectos-container {
        grid-template-columns: 1fr;
    }

    .container-all-proyectos {
        width: 70%;
        height: 300px;
    }

    .button-back {
        width: 60%;
    }
}
.nav-container {
    width: 100%;
    min-height: 10vh;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    align-items: center;
    position: fixed;
    z-index: 5;
}

.nav-container-active {
    /* background-color: rgba(0, 0, 0, 0.566); */
    background-color: rgba(0, 0, 0, 0.566);
    width: 100%;
    min-height: 10vh;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    align-items: center;
    position: fixed;
    z-index: 5;
}

.nav-container-black {
    background-color: black;
    width: 100%;
    min-height: 10vh;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    align-items: center;
    /* position: fixed; */
    z-index: 5;
}

.link-container {
    width: auto;
    display: flex;
    gap: 2rem;
    margin-right: 10px;
}


.link-path {
    text-decoration: none;
    font-size: 18px;
    font-weight: 100;
    color: white;
    padding: 5px;
    cursor: pointer;
    position: relative;
    background-color: transparent;
    border: none;
}

.link-path::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    /* Altura del borde */
    background-color: white;
    transform: scaleX(0);
    /* Inicia escondido */
    transition: transform 0.3s ease;
    /* Transición suave */
}

.link-path:hover::after {
    transform: scaleX(1);
    /* Muestra el borde al hacer hover */
}

.img-logo {
    width: 120px;
    height: 90px;
}

.logo-nav {
    width: 100%;
    height: 100%;
}

.div-menuhamburguesa {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid white;
    border-radius: 3px;
    background-color: white;
}

.menuhamburguesa {
    color: black;
    width: 24px;
    height: 24px;
}


@media screen and (max-width: 767px) {
    .link-container {
        display: none;
        /* Ocultar los enlaces en dispositivos móviles */
    }
}

@media screen and (min-width: 767px) {
    .menuhamburguesa {
        display: none;
    }

    .div-menuhamburguesa {
        display: none;
    }
}
.div-aboutus {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 20px;
    background-color: whitesmoke;

}

.div-title-about {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    margin-top: 4rem;
    margin-bottom: 2rem;
}

.div-title-estudio {
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 15px;
    margin-right: 130px;
}

.title-about {
    font-size: 28px;
    font-weight: 200;
    text-align: end;
    text-shadow: 0px 5px 10px black;
    color: black;
}

.title-about2 {
    font-size: 28px;
    font-weight: 400;
    color: black;
    text-align: end;
    text-shadow: 0px 5px 10px black;

}

.text-jad {
    width: 80%;
    display: flex;
    font-size: 24px;
    font-weight: 200;
    color: black;
    text-align: center;
}

.container-map {
    width: 100%;
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    justify-content: space-around;
    margin-bottom: 20px;

}

.div-nombres {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.div-nombres .texto-nombre {
    width: 70%;
    font-size: 18px;
    color: black;
    text-align: center;
    padding: 3px;

}



.container-map .img-quienes-somos {
    width: 70%;
    box-shadow: 1px 3px 10px 4px rgba(0, 0, 0, 0.2);
}




@media screen and (min-width:320px) and (max-width:770px) {
    .div-aboutus {
        flex-direction: column;
        padding-top: 2rem;
    }

    .container-map {
        flex-direction: column;
        align-items: center;
        margin: 0;
        margin-top: 50px;
    }

    .div-title-estudio {
        justify-content: center;
        margin: 0;
        /* margin-right: 100px; */
        padding: 0; /*se saco padding para ver como se ve en ios */
        background-color: black;
    }
    .title-about2{
        color: white;
    }
    .title-about{
        color: white;
    }
    .div-nombres {
        width: 60%;
    }
    .div-nombres .texto-nombre {
        width: 100%;
        margin-bottom: 15px;
    }
    .container-map .img-quienes-somos {
        width: 100%;
    }
    .div-title-about {
        margin: 0;
    }
}
@media screen and (min-width:320px) and (max-width:500px){
    .title-about2{
        font-weight: 200;
    }
    .div-title-estudio{
        padding-top: 2px;
    }
}
.div-all-contact{
  width: 100%;
  min-height: 80vh;
  background-color: whitesmoke;
  padding-top: 2rem;
  overflow-x: hidden;
}
.div-contact {
  width: 100%;
  min-height: 80vh;
  display: flex;
  background-color: whitesmoke;
  background-image: url('../../assets/imagenes/contacto-fondo2.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  
}

.div-container-contact {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.div-contacto-formulario {
  width: 80%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.637);
  gap: 10px;
  padding: 10px;
  border-radius: 3px;
}

.div-hablemos {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.todo-proyecto {
  font-size: 32px;
  text-align: center;
  font-weight: 100;
  color: white;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 1);

}

.text-hablemos {
  font-size: 20px;
  text-align: center;
  font-weight: 600;
  color: white;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 1);


}

.text-dejanos-datos {
  font-size: 32px;
  text-align: center;
  font-weight: 100;
  color: white;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
}

.text-equipo {
  font-size: 18px;
  text-align: center;
  font-weight: 600;
  color: white;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 1);


}

.div-contact-media {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.social-media {
  display: flex;
  align-items: center;
  gap: 5px;
  color: white;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 1);

}
.social-media-p{
  font-size: 14px;
}
.form-contacto {
  width: 30%;
  min-height: 70vh;
  background-color: rgba(0, 0, 0, 0.541);
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin: auto;
  gap: 10px;
  padding: 10px;
  border-radius: 3px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.input-form,
.area-form {
  width: 100%;
  border: none;
  border-bottom: 2px solid #d6d6d6;
  padding: 10px 5px;
  font-size: 18px;
  font-weight: 200;
  background-color: whitesmoke;
  /* color: white; */

}

.label-form {
  font-weight: 300;
  text-align: start;
  color: white;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
}

.area-form {
  resize: none;
  min-height: 20vh ;
}

.option-select {
  font-size: 18px;
  font-weight: 200;
  color: black;
}

.input-form:focus,
.area-form:focus {
  /* border-bottom-color: grey; */
  outline: none;
}
.container-recaptcha{
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.button-form {
  width: 100%;
  padding: 10px 20px;
  background-color: rgb(163, 163, 163);
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button-form:hover {
  background-color: rgb(116, 115, 115);
}

.success-message {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  height: 15vh;
  border: 1px solid;
  text-align: center;
}

.error-message {
  width: 70%;
  font-size: 15px;
  color: rgb(255, 215, 215);
  font-weight: 200;
}



@media screen and (min-width:320px) and (max-width:700px) {
  .div-contact {
    flex-direction: column;
    align-items: center;
  }

  .div-container-contact {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .form-contacto {
    width: 80%;
    align-items: center;
  }
 
  .input-form,
  .area-form {
    width: 70%;
  }

  .label-form {
    width: 70%;
    text-align: left;
  }

  .button-form {
    width: 70%;
  }
  .todo-proyecto,
  .text-hablemos,
  .text-dejanos-datos,
  .text-equipo {
    font-size: 20px;
  }
}
@media screen and (min-width:320px) and (max-width:350px){
  .form-contacto{
    width: 95%;
  }
  .div-contacto-formulario{
    width: 95%;
  }
}

@media screen and (min-width:320px) and (max-width:500px) {
  .div-contact-media {
    flex-direction: column;
    align-items: start;
    gap: 10px;
    margin-left: 10px;
    margin-top: 10px;
 
  }

  .todo-proyecto,
  .text-hablemos,
  .text-dejanos-datos,
  .text-equipo {
    font-size: 20px;
  }

  .social-media {
    justify-content: center;
    align-items: center;
    font-size: 16px;
  }
}

@media screen and (min-width:701px) and (max-width:1000px){
 
  .div-contacto-formulario{
    width: 90%;
    height: 75vh;
  }
  .div-contact-media {
    flex-direction: column;
    align-items: start;
    gap: 10px;
    margin-left: 10px;
    margin-top: 10px;
  }
.form-contacto{
  width: 40%;
  margin-right: 10px;
}
}
.div-arquitecture {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: whitesmoke;
    overflow: hidden;
}

.arquitecture-gallery {
    position: relative;
    width: 100%;
    height: 100vh;
    /* Asegura que el contenedor de la imagen ocupe toda la altura del viewport */
}

.arquitecture-gallery :before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.353);
    z-index: 1;
}

.img-arquitecture-carrousel {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
}

.div-text-construction {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    color: white;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
    gap: 15px;
}

.texto-bienvenida-construction {
    font-size: 30px;
    text-align: center;
    font-weight: 100;
}

.texto-presentacion-construction {
    font-size: 40px;
    font-weight: 300;
    font-style: italic;
    text-align: center;
}

.container-presentacion-construccion {
    width: 100%;
    min-height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    margin-top: 10px;
}

.p-presentacion-construccion {
    width: 100%;
    font-size: 18px;
    font-weight: 200;
    text-align: center;
}

.div-container-construccion {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
}

.div-container-item-construccion {
    width: 90%;
    height: 70vh;
    display: flex;
    align-items: center;
    /* background-color: aqua; */
}

.div-contain-info-construccion {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.container-construccion-propiedad {
    width: 90%;
    margin-bottom: 20px;
}

.p-construccion-propiedad {
    text-align: start;
    font-size: 18px;
    font-weight: 300;
    font-style: italic;
}

.div-proyecto-obra-construccion {
    width: 90%;
    min-height: 80vh;
}

.title-construccion {
    font-size: 20px;
    font-weight: 600;
}

.info-detallada-construccion {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.h2-title-construccion {
    font-size: 20px;
    font-weight: 300;
    margin-top: 10px;
}

.p-subtitle-construccion {
    font-size: 20px;
    font-weight: 200;
    font-style: italic;
    border-bottom: 1px solid black;
}

.descripcion-construccion {
    width: 100%;
    font-weight: 200;
    font-size: 16px;
    text-wrap: wrap;
}

.div-imagen-construccion {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imagen-construccion {
    width: 100%;
    height: 100%;
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
}

@media screen and (min-width: 320px) and (max-width:990px) {
    .div-container-item-construccion {
        flex-direction: column;
        height: 100%;
        gap: 20px;
    }

    .div-contain-info-construccion {
        width: 80%;
    }

    .imagen-construccion {
        width: 80%;
    }

    .div-imagen-construccion {
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    .div-proyecto-obra-construccion {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        min-height: 50%;
        align-items: center;
    }
}

@media screen and (min-width: 320px) and (max-width:990px) {
    .container-construccion-propiedad {
        width: 100%;
    }

}